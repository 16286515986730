import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ZapisyComponent} from './components/zapisy/zapisy.component';
import {AppComponent} from './app.component';
import {KomponentyComponent} from './components/komponenty/komponenty.component';
import {WiadomoscComponent} from './components/wiadomosci/wiadomosc/wiadomosc.component';
import {WiadomosciComponent} from './components/wiadomosci/wiadomosci.component';
import {WynkilokalneComponent} from './components/wynkilokalne/wynkilokalne.component';
import {WynikiBBOComponent} from './components/wyniki-bbo/wyniki-bbo.component';
import {CezarComponent} from './components/cezar/cezar.component';


const routes: Routes = [
  { path: 'lista', component: ZapisyComponent},
  { path: 'komponenty', component: KomponentyComponent},
  { path: 'wiadomosci', component: WiadomosciComponent},
  { path: 'wyniki', component: WynkilokalneComponent},
  { path: 'wynikiBBO/:id', component: WynikiBBOComponent},
  { path: 'cezar', component: CezarComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
