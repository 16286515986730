import { Component, OnInit } from '@angular/core';
import {SelectItem} from 'primeng';


interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-komponenty',
  templateUrl: './komponenty.component.html',
  styleUrls: ['./komponenty.component.css']
})
export class KomponentyComponent implements OnInit {

  cities1: SelectItem[];

  cities2: City[];

  selectedCity1: City;

  selectedCity2: City;
  value: Date;

  items: SelectItem[];
  item: string;
  constructor() {
    this.cities1 = [
      {label:'Select City', value:null},
      {label:'New York', value:{id:1, name: 'New York', code: 'NY'}},
      {label:'Rome', value:{id:2, name: 'Rome', code: 'RM'}},
      {label:'London', value:{id:3, name: 'London', code: 'LDN'}},
      {label:'Istanbul', value:{id:4, name: 'Istanbul', code: 'IST'}},
      {label:'Paris', value:{id:5, name: 'Paris', code: 'PRS'}}
    ];

    //An array of cities
    this.cities2 = [
      {name: 'New York', code: 'NY'},
      {name: 'Rome', code: 'RM'},
      {name: 'London', code: 'LDN'},
      {name: 'Istanbul', code: 'IST'},
      {name: 'Paris', code: 'PRS'}
    ];
    this.items = [];
    for (let i = 0; i < 10000; i++) {
      this.items.push({label: 'Item ' + i, value: 'Item ' + i});
    }
  }

  ngOnInit(): void {
  }

}
