import {Component, OnInit, ViewChild} from '@angular/core';
import {BboZawodnik} from '../../classess/bbo-zawodnik';
import {BboZawodnikService} from '../../services/bbo-zawodnik.service';
import {Cezar} from '../../classess/cezar';
import {CezarService} from '../../services/cezar.service';
import {TypKolumny} from '../../classess/typ-kolumny.enum';

@Component({
  selector: 'app-cezar',
  templateUrl: './cezar.component.html',
  styleUrls: ['./cezar.component.css']
})
export class CezarComponent implements OnInit {
  cezar: Cezar[];
  cols: any[];
  typKolumnyTabelaEnum: any = TypKolumny;

  constructor(private cezarService: CezarService) { }

  ngOnInit() {
    this.getCezar();

    this.cols = [
                  {field: 'pid', header: 'pid', type: TypKolumny.STRING },
                  {field: 'nazwisko', header: 'Nazwisko', type: TypKolumny.STRING },
                  {field: 'imie1', header: 'Imię', type: TypKolumny.STRING },
                  {field: 'sezon', header: 'Sezon', type: TypKolumny.NUMBER },
                  {field: 'asezon', header: 'aSezon', type: TypKolumny.NUMBER },
                  {field: 'wk', header: 'WK', type: TypKolumny.NUMBER },
                  {field: 'pkl', header: 'PKL', type: TypKolumny.NUMBER },
                  {field: 'apkl', header: 'aPKL', type: TypKolumny.NUMBER },
                  {field: 'klub', header: 'Klub', type: TypKolumny.STRING },
                  {field: 'liga', header: 'Liga', type: TypKolumny.STRING }
                ];
  }

  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  onRepresentativeChange(event) {
 //  this.zawodnicy.filter(event.value, 'representative', 'in')
  }

  getCezar(): void {
    this.cezarService.getAll().subscribe(
      (res: Cezar[]) => {
        this.cezar = res;
       // this.dataSource = new MatTableDataSource(this.zawodnicy);
       // this.dataSource.sort = this.sort;
      },
      (err) => {
        this.cezar = err;
      }
    );
  }
}
